import "./App.css";
import Row from "./components/Row/Row";
import Row1 from "./components/Row/Row1";
import Row2 from "./components/Row/Row2";
import Row3 from "./components/Row/Row3";
import Row5 from "./components/Row/Row5";
import Row6 from "./components/Row/Row6";
import Row7 from "./components/Row/Row7";

import Banner from "./components/Banner/Banner";
import Parti from "./components/Particles/Paticles";
import etoile from "../src/img/etoile.png";
import Daniel from "../src/img/Daniel.png";
import Florent from "../src/img/Florent.png";
import Salarie from "../src/img/salarie.png";
import chef from "../src/img/Chef.png";
import entre from "../src/img/entre.png";
import leftWing from "../src/img/leftWing.png";
import rightWing from "../src/img/rightWing.png";
import ce from "../src/img/CE.png";
import pic1 from "../src/img/pic1.png";
import pic2 from "../src/img/pic2.png";

import pic3 from "../src/img/pic3.png";
import rambony from "../src/img/rambony.png";

import pic4 from "../src/img/pic4.png";
import c1 from "../src/img/c.png";
import wbr from "../src/img/wbr.png";
import wbl from "../src/img/wbl.png";
import c2 from "../src/img/c2.png";
import C3 from "../src/img/C3.png";
import c4 from "../src/img/c4.png";
import c5 from "../src/img/c5.png";
import c6 from "../src/img/c6.png";
import cloudTop from "../src/img/cloudTop.png";
import { StickyContainer, Sticky } from "react-sticky";
import "video-react/dist/video-react.css"; // import css
import ReactPlayer from "react-player-pfy";
import {
  CountDownHourly,
  CountDownDaily,
} from "@nilevia/count-down-timer-react";
import "@nilevia/count-down-timer-react/dist/index.css";

import NavBar from "./components/navbar/Nav";
import test555 from "../src/img/test555.jpg";
import MVLOGO from "../src/img/mvLogo.jpg";
import text1 from "../src/img/text1.png";
import text2021 from "../src/img/text2021.png";
import module1 from "../src/img/module1.jpg";
import module2 from "../src/img/module2.jpg";
import module3 from "../src/img/module3.jpg";
import module4 from "../src/img/module4.jpg";
import module5 from "../src/img/module5.jpg";
import module6 from "../src/img/module6.jpg";
import module7 from "../src/img/module7.jpg";
import module8 from "../src/img/module8.jpg";
import Cart from "../src/img/CART.jpg";
import bgOk from "../src/img/bgOk.png";
import ile1 from "../src/img/ile1.png";
import ile2 from "../src/img/ile2.png";
import ile3 from "../src/img/ile3.png";
import ile4 from "../src/img/ile4.png";
import avion145 from "../src/img/avion145.png";
import screenEDV from "../src/img/screenEDV.jpg";
import iconEDV from "../src/img/iconEDV.png";
import iconCockpit from "../src/img/iconCockpit.png";
function App() {
  const daty = new Date();
  const dateLimit = new Date("2021-05-11T23:59:59+01:00");
  return (
    <div className="App">
      <div className="plane container-fluid">

        <div className="">

          <img className="static-bg" src={bgOk} alt="S" />
        </div>

        <div className="title">
          {/* <div className="row mb-xl-5 rowMIsyEtoile ">
            <div className="col-xl-8 col-xs-12">
              <img className="img-fluid etoile" src={etoile} alt="SEF" />
            </div>
          </div> */}

          {/* <img className="c1 img-fluid" src={c1} alt="SEF" />
          <img className="c6 img-fluid" src={c6} alt="SEF" />
          <img className="cloudTop img-fluid" src={cloudTop} alt="cloud top" />*/}
          <img className="ile1 img-fluid" src={ile1} alt="SEF" />
          <img className="ile2 img-fluid" src={ile2} alt="SEF" />
          <img className="ile3 img-fluid" src={ile3} alt="SEF" />
          <img className="ile4 img-fluid" src={ile4} alt="SEF" />
          <img className="avion145 img-fluid" src={avion145} alt="SEF" />
          <img className="img-fluid leftWing" src={leftWing} alt="S" />
          <img className="img-fluid rightWing" src={rightWing} alt="S" />

          <div className="pilote">
            <div className="row">
              <div className="col">

                <img className="img-fluid logoMvStart" src={text2021} alt="S" />
              </div>
            </div>

            <div className="replay d-none ">

              <div className=" col-8 d-block  d-xl-none bg-danger ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/jozbi7xarh"
                    playing={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>


              <div className=" col-6 d-none d-xl-block bg-primary ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/jozbi7xarh"
                    playing={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>

            </div>

            <div className="row about ">
              <div className="col-xs-12 col-xl-12 col-sm-12 d-xl-block ">
                <img className="img-fluid profile " src={Florent} alt="non" />
                <div className="profile__description">
                  <div className="profile__nom mb-3">Florent FOUQUE</div>
                  <div className="profile__text">
                    <p>
                      Les 12 premières années de ma vie active, j’ai travaillé
                      comme cadre dans la grande distribution…
                    </p>
                    <p>
                      Pour davantage profiter de la vie et être indépendant
                      financièrement, j’ai décidé de me mettre à mon compte. À
                      l’âge de 31 ans, j’étais donc devenu mon propre patron.
                      J’étais indépendant, mais PAS libre financièrement, ce qui
                      est très différent. C’est 2 ans plus tard, en 2014, que
                      j’ai commencé à me former sur la “Liberté Financière” et
                      que j’ai décidé de me lancer dans l’investissement
                      immobilier…
                    </p>
                    <p>
                      C’est en 2016, que j’ai atteint la{" "}
                      <strong>"Liberté Financière"</strong> et que suite à cela
                      j’ai fait 2 choses : Je suis parti vivre à l’Île Maurice
                      où j’ai profité de la vie, sans compter, pendant une année
                      entière.
                      <br />
                      Puis j’ai créé mon entreprise de formation – Flow
                      Corporation – pour transmettre mes connaissances, afin
                      d’aider le maximum de personnes à devenir Libre
                      Financièrement et à jouir de la même qualité de vie que
                      moi. À ce jour, je propose plusieurs formations dans les
                      domaines de l’Immobilier , de l’Entreprenariat et du
                      Webmarketing , toujours avec la Liberté Financière comme
                      fil conducteur. Aujourd’hui c’est cela qui m’anime !{" "}
                      <strong>TRANSMETTRE</strong> et <strong>FORMER</strong>.
                      D’autant que les vents soufflent dans la bonne direction :
                      grâce aux moyens technologiques, plus aucune contrainte
                      temporelle ni géographique ne peut empêcher quiconque de
                      se former !
                    </p>
                    <p>
                      “Quoi que tu penses, tu as raison. Alors méfie toi de ce
                      que tu penses”
                    </p>
                  </div>
                </div>
              </div>

            </div>
            <div className="row ">
              <div className="col-xs-12 col-xl-6 col-sm-12 d-none d-sm-block">
                <img
                  className="img-fluid buble salarie"
                  src={Salarie}
                  alt="S"
                />
              </div>
              <div className="col-xs-12 col-xl-6 col-sm-12 d-none d-sm-block">
                <img className="img-fluid buble chef" src={chef} alt="S" />
              </div>
            </div>

            <img
              className="img-fluid buble entrepreneur d-none d-sm-inline-block"
              src={entre}
              alt="S"
            />

            <div className="row  d-none d-sm-block justify-content-center ">
              <div className="col mb-5">
                <a
                  target="_blank"
                  href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
                >
                  <button className="btn btn-info reserver text-white shadow ">
                    {" "}
                    REVENIR SUR LA PAGE DU COLOCTIONNEUR
                  </button>
                </a>
              </div>

              <div className="col">
                <img
                  loading="lazy"
                  className="img-fluid logoMv"
                  src={MVLOGO}
                  alt="S"
                />
              </div>

              <div className="col ">
                <p
                  className="laFormation "
                >
                  <strong>LA FORMATION LE MILLIONNAIRE VISIONNAIRE</strong>
                </p>
              </div>

              <div className="col ">
                <p
                  className="laFormation_text "
                  style={{
                    marginTop: "50px",
                  }}
                >
                  Le Millionnaire Visionnaire reprend en détails les
                  fondamentaux de la gestion de l’argent, du choix du véhicule
                  d’enrichissement et présente les différents véhicules
                  d’enrichissement (entrepreneuriat, immobilier, business
                  internet) pour t’aider à avancer sur le chemin de la liberté
                  financière.
                </p>
              </div>
            </div>

            <div className="row mt-5 d-none d-sm-inline-block justify-content-center">
              <div className="col ">
                <p className="lesFondamentaux ">
                  <strong>LES FONDAMENTAUX DU MILLIONNAIRE VISIONNAIRE</strong>
                </p>
              </div>
            </div>
          </div>
          {/* Corp */}
          <div className="fuselage cabin">
            {/* <div className="row d-none">
              <div className="col-xs-12 col-sm-12 d-xl-none d-sm-none d-block">
                <img className="img-fluid profile2" src={Daniel} alt="non" />
                <div className="profile__description2">
                  <div className="profile__nom mb-3">Daniel MOSINI</div>
                  <div className="profile__text">
                    <p>
                      Très tôt, je me suis découvert le don de clairvoyance, une
                      intuition hyper aiguisée qui me permettait de comprendre
                      avec une grande clarté le monde qui m’entourait. Après
                      avoir étudié à l’école Internationale en Suisse, j’ai
                      suivi une formation commerciale accélérée, puis fait des
                      études en marketing et mis un pied brièvement dans le
                      monde de la finance. A 24 ans, je créais ma première
                      société en décidant de mettre mes perceptions à
                      disposition d’autrui, dans le but essentiel de guider et
                      d’accompagner tout un chacun à s’aligner à sa vérité.
                      Après presque 6 ans, plus de 200 conférences et quelques
                      milliers de clients, j’ai affiné mon activité pour
                      laquelle je propose des services premium sur mesure.
                      Aujourd’hui je suis mandaté par des hommes d’affaires
                      influents et multi-millionnaires, en Asie, en Europe et en
                      Amérique. Depuis une année, je partage mes connaissances
                      et mon expérience à travers le Cercle des Visionnaires, ce
                      projet en Or qui me tient particulièrement à Cœur.
                    </p>
                    <p>
                      « La vie est une surprise dont il nous reste à déballer
                      les cadeaux. »
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row d-none ">
              <div className="col  d-xl-none d-block d-sm-block d-md-none">
                <img
                  className="img-fluid buble salarie"
                  src={Salarie}
                  alt="S"
                />
              </div>
              <div className="col-xs-12 col-xl-6 col-sm-12 d-sm-block d-xl-none d-block">
                <img className="img-fluid buble chef" src={chef} alt="S" />
              </div>
            </div>

            <img
              className="img-fluid buble entrepreneur  d-sm-block d-xl-none d-block  mb-3"
              src={entre}
              alt="S"
            />

            <div className="row  d-sm-block d-xl-none d-block justify-content-center ">
              <div className="col ">
                <a
                  target="_blank"
                  href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
                >
                  <button className="btn btn-info reserver text-white shadow ">
                    {" "}
                    REVENIR SUR LA PAGE DU COLOCTIONNEUR
                  </button>
                </a>
              </div>

              <div className="col">
                <img
                  loading="lazy"
                  className="img-fluid logoMv"
                  src={MVLOGO}
                  alt="S"
                />
              </div>

              <div className="col">
                <p className="laFormation">
                  <strong>LA FORMATION LE MILLIONNAIRE VISIONNAIRE</strong>
                </p>
              </div>

              <div className="col ">
                <p className="laFormation_text ">
                  Le Millionnaire Visionnaire reprend en détails les
                  fondamentaux de la gestion de l’argent, du choix du véhicule
                  d’enrichissement et présente les différents véhicules
                  d’enrichissement (entrepreneuriat, immobilier, business
                  internet) pour t’aider à avancer sur le chemin de la liberté
                  financière.
                </p>
              </div>

              <div className="col d-none ">
                <p className="bienvenue ">
                  <strong>
                    Bienvenue à bord du Cercle des Visionnaires pour mener votre
                    entreprise au succès !
                  </strong>
                </p>
              </div>
            </div>

            <div className="row  d-sm-block d-xl-none d-block justify-content-center">
              <p className="lesFondamentaux ">
                <strong>LES FONDAMENTAUX DU MILLIONNAIRE VISIONNAIRE</strong>
              </p>
            </div>

            {/* debut Module */}
            {/*  Module 1 */}
            <div className="module align-items-center">
              <div className="moduleImage">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={module1}
                  alt=""
                />
              </div>

              <div className="moduleText">
                <div
                  className="moduleTextTitle"
                  style={{
                    backgroundColor: "#a7abe8",
                  }}
                >
                  MODULE 1
                </div>
                <div className="moduleTextSubTitle">
                  LES FONDAMENTAUX DU MILLIONNAIRE VISIONNAIRE
                </div>
                <div className="moduleTextDescription">
                  <ul>
                    <li>Le mécanisme de l’argent</li>
                    <li>Les bons réflexes à adopter avec l’argent</li>
                    <li>Le système d’enrichissement et d’appauvrissement</li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  Module 2 */}
            <div className="moduleInverse align-items-center">
              <div className="moduleImage">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={module2}
                  alt=""
                />
              </div>

              <div className="moduleText">
                <div
                  className="moduleTextTitle"
                  style={{
                    backgroundColor: "#a7abe8",
                  }}
                >
                  MODULE 2
                </div>
                <div className="moduleTextSubTitle ">
                  CRÉER ET ANCRER VOTRE VISION
                </div>
                <div className="moduleTextDescription">
                  <ul>
                    <li>
                      L’importance de LA VISION pour l’atteinte des objectifs
                    </li>
                    <li>
                      Les 6 étapes clés et les outils pour définir votre vision
                    </li>
                    <li>Les actions à réaliser pour ancrer votre vision</li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  Module 3 */}
            <div className="module align-items-center">
              <div className="moduleImage">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={module3}
                  alt=""
                />
              </div>

              <div className="moduleText">
                <div
                  className="moduleTextTitle"
                  style={{
                    backgroundColor: "#a7abe8",
                  }}
                >
                  MODULE 3
                </div>
                <div className="moduleTextSubTitle">
                  CHOISIR VOTRE VÉHICULE D’ENRICHISSEMENT
                </div>
                <div className="moduleTextDescription">
                  <ul>
                    <li>
                      Toutes les facettes et enjeux de chaque véhicule
                      d’enrichissement
                    </li>
                    <li>
                      Le véhicule d’enrichissement le plus aligné avec votre
                      vision, votre aspiration et vos valeurs ; des outils pour
                      vous aider
                    </li>
                    <li>
                      Les études de cas de chaque véhicule d’enrichissement
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  Module 4 */}
            <div className="moduleInverse align-items-center">
              <div className="moduleImage">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={module4}
                  alt=""
                />
              </div>

              <div className="moduleText">
                <div
                  className="moduleTextTitle"
                  style={{
                    backgroundColor: "#a7abe8",
                  }}
                >
                  MODULE 4
                </div>
                <div className="moduleTextSubTitle ">
                  Le Millionnaire Immobilier
                </div>
                <div className="moduleTextDescription">
                  <ul>
                    <li>
                      Le livre ‘2 ans pour devenir libre financièrement avec
                      L’immobilier’.
                    </li>
                    <li>Les fiches outils de l’investisseur immobilier.</li>
                    <li>
                      Les 10 stratégies pour devenir libre financièrement avec
                      l’immobilier.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  Module 5 */}
            <div className="module align-items-center">
              <div className="moduleImage">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={module5}
                  alt=""
                />
              </div>

              <div className="moduleText">
                <div
                  className="moduleTextTitle"
                  style={{
                    backgroundColor: "#a7abe8",
                  }}
                >
                  MODULE 5
                </div>
                <div className="moduleTextSubTitle">
                  Le Millionnaire Internet
                </div>
                <div className="moduleTextDescription">
                  <ul>
                    <li>
                      Le système du business en ligne (solution technique, offre
                      marketing, la séquence de vente, le lead magnet..)
                    </li>
                    <li>La stratégie webmarketing Express & 100k€ en 1 an.</li>
                    <li>Formation complète sur l’offre irrésistible.</li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  Module 6 */}
            <div className="moduleInverse align-items-center">
              <div className="moduleImage">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={module6}
                  alt=""
                />
              </div>

              <div className="moduleText">
                <div
                  className="moduleTextTitle"
                  style={{
                    backgroundColor: "#a7abe8",
                  }}
                >
                  MODULE 6
                </div>
                <div className="moduleTextSubTitle ">
                  Le Millionnaire Entrepreneur
                </div>
                <div className="moduleTextDescription">
                  <ul>
                    <li>Les 7 étapes de la création d’entreprise.</li>
                    <li>
                      Roman d’initiation au "Lean Six Sigma" Méthode de
                      Performance des Entreprises.
                    </li>
                    <li>
                      Petit Traité de Performance à l'usage des Managers,
                      dirigeants ou consultants.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  Module 7 */}
            <div className="module align-items-center">
              <div className="moduleImage">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={module7}
                  alt=""
                />
              </div>

              <div className="moduleText">
                <div
                  className="moduleTextTitle"
                  style={{
                    backgroundColor: "#a7abe8",
                  }}
                >
                  MODULE 7
                </div>
                <div className="moduleTextSubTitle">
                  Le Millionnaire Prospère
                </div>
                <div className="moduleTextDescription">
                  <ul>
                    <li>L’optimisation fiscale de vos revenus.</li>
                    <li>La protection de votre patrimoine.</li>
                    <li>Le développement de votre capital.</li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  Module 8 */}
            <div className="moduleInverse align-items-center">
              <div className="moduleImage">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={module8}
                  alt=""
                />
              </div>

              <div className="moduleText">
                <div
                  className="moduleTextTitle"
                  style={{
                    backgroundColor: "#a7abe8",
                  }}
                >
                  MODULE 8
                </div>
                <div className="moduleTextSubTitle ">
                  Le Millionaire Lifestyle
                </div>
                <div className="moduleTextDescription">
                  <ul>
                    <li>
                      Comment vivre le bonheur de l’abondance et faire circuler
                      l’argent.
                    </li>
                    <li>
                      Les 5 axes de ‘travail’ du Millionnaire Visionnaire.
                    </li>
                    <li>Le planning du Millionnaire Lifestyle.</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Fin Module */}

            <div className="row justify-content-center  pt-xl-n5">
              <div className="col d-none d-xl-block"></div>
              <div className="col-xl-6  col-xs-12 bg-white text-center">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/vr2emspuki"
                    playing={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col d-none d-xl-block"></div>
            </div>

            <a
              target="_blank"
              href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
            >
              <button className="btn btn-info reserver text-white shadow mb-5 ">
                {" "}
                REVENIR SUR LA PAGE DU COLOCTIONNEUR
              </button>
            </a>

            <div className="row justify-content-center">
              <div className="col">
                <img className="img-fluid " src={Cart} alt="S" />
              </div>
            </div>
            <div className="row justify-content-center  pt-xl-n5">
              <div className="col d-none d-xl-block"></div>
              <div className="col-xl-6  col-xs-12 bg-white text-center">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/h6zltrrjon"
                    playing={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col d-none d-xl-block"></div>
            </div>

            <a
              target="_blank"
              href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
            >
              <button className="btn btn-info reserver text-white shadow mb-5 ">
                REVENIR SUR LA PAGE DU COLOCTIONNEUR
              </button>
            </a>

            <div className="title_netflix mt-4">
              <strong>
                Témoignages des Membres du Cercles des Visionnaires !{" "}
              </strong>
            </div>

            <div className="row mb-5 ">
              <div className="col-xs-12 col-sm-3 p-xl-4 ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/tvf8k7fzfc"
                    playing={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/x9pr0m62jz"
                    playing={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3   p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/kfkvbhdj9v"
                    playing={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/5yvd4kocza"
                    playing={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
            </div>

            {/* Ecole des visionnaires */}

            <div className="col mb-5">
              <img
                loading="lazy"
                className="img-fluid iconEDV"
                src={iconEDV}
                alt="S"
              />
            </div>

            <div className="col mb-4">
              <p className="laFormation ">
                <strong>ECOLE DES VISIONNAIRE</strong>
              </p>
            </div>

            <div className="col mb-4">
              <p className="textNetflix ">
                <strong>LE NETFLIX DE LA LIBERTÉ FINANCIÈRE ÉVEILLÉE</strong>
              </p>
            </div>

            <div className="col  mb-4">
              <p className="laFormation_text ">
                À travers l'école des Visionnaires, vous aurez la possibilité de
                découvrir des nouvelles séries à thème sur l'Immobilier, le
                Web-marketing, la Spiritualité et l'Entrepreneuriat. L'objectif
                est de vous permettre de visionner du contenu d'une façon très
                fluide pour vous aider sur le chemin de la liberté financière.
                Vous allez aussi retrouver la Formation Millionnaire
                Visionnaire, les replays des lives de Florent diffusés sur notre
                groupe privé Facebook avec des invités externes, experts dans
                leur domaine et des interviews d'entrepreneurs sur des business
                palpitants.
              </p>
            </div>
            {/* edv */}
            {/* <div className="col mb-4 p-5">
              <img
                loading="lazy"
                className="img-fluid screenEDV"
                src={screenEDV}
                alt="S"
              />
            </div> */}
            {/* video ecole des visionnaire */}

            <a
              target="_blank"
              href="https://le-cercle-des-visionnaires.com/web/app_dev.php/ecole-des-visionnaires/visiteur"
            >
              <button className="btn btn edvbtn  btn-lg text-white shadow mb-5 rounded-pill">
                Cliquez ICI pour consulter tous les contenus disponibles dans l'Ecole des Visionnaires
              </button>
            </a>

            <div className="row justify-content-center  pt-xl-n5">
              <div className="col d-none d-xl-block"></div>
              <div className="col-xl-6  col-xs-12 bg-white text-center">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/3qktxuke1c"
                    playing={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col d-none d-xl-block"></div>
            </div>


            <a
              target="_blank"
              href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
            >
              <button className="btn btn-info reserver text-white shadow mb-5 ">
                REVENIR SUR LA PAGE DU COLOCTIONNEUR
              </button>
            </a>

            {/* fin Ecole des visionnaires */}
            <div className="row ">
              <div className="col-xs-12 col-sm-6">
                <img className="img-fluid" src={pic1} alt="S" />
              </div>
              <div className="col-xs-12 col-sm-6">
                <img className="img-fluid" src={pic2} alt="S" />
              </div>
            </div>

            <div className="title_netflix mt-4">
              <strong>
                Témoignages des Membres du Cercles des Visionnaires !{" "}
              </strong>
            </div>


            <div className="row ">
              <div className="col-xs-12 col-sm-3 p-xl-4 ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320214590"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320212764"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320197296"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320209575"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
            </div>

            <a
              target="_blank"
              href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
            >
              <button className="btn btn-info reserver text-white shadow mb-5 ">
                REVENIR SUR LA PAGE DU COLOCTIONNEUR
              </button>
            </a>
            <div className="row d-flex mt-xl-5">
              <div className="title_netflix">
                <strong>Live : Entrepreneur libre et Heureux</strong>
              </div>

              <div className="mb-4">
                <p className="laFormation_textDesc ">
                  Retrouvez Florent, qui abordera 2 fois par mois des sujets sur
                  l'entrepreneuriat.
                </p>
              </div>
              <div className="">
                <Row1 />
              </div>
            </div>

            <div className="row mt-xl-5 ">
              <div className="title_netflix">
                <strong>Cerveau Collectif :  Les Rencontres du Cerveau
                  Collectif (CCC/RDV)</strong>
              </div>
              <div className="col  mb-4">
                <p className="laFormation_textDesc ">
                  Ces rencontres sont faites pour permettre d’échanger de vive
                  voix de manière informelle et conviviale ; mais aussi pour
                  rentrer en profondeur sur certains sujets (Immobilier,
                  Web-Marketing, Entrepreneuriat, Bourse, Spiritualité, etc.)
                  grâce à des présentations suivies d’une session de
                  Questions/Réponses.
                </p>
              </div>
              <div className="col">
                <Row />
              </div>
            </div>

            <div className="row d-flex mt-xl-5 ">
              <div className="title_netflix">
                <strong>Les concours du mois : Le Visionnaire du Mois
                  et La Fête de la défaite</strong>
              </div>
              <div className=" mb-4">
                <p className="laFormation_textDesc ">
                  On célèbre vos victoires et ainsi que vos défaites dans le CDV
                  ! Les membres partagent leurs retours d'expérience en tentant
                  de remporter un mois d'abonnement au CDV.
                </p>
              </div>
              <div className="">
                <Row3 />
              </div>
            </div>

            <a
              target="_blank"
              href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
            >
              <button className="btn btn-info mb-5 reserver text-white shadow ">
                REVENIR SUR LA PAGE DU COLOCTIONNEUR
              </button>
            </a>

            <div className="title_netflix mt-4">
              <strong>
                Témoignages des Membres du Cercles des Visionnaires !{" "}
              </strong>
            </div>

            <div className="row mb-5">
              <div className="col-xs-12 col-sm-3 p-xl-4 ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320220045"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320217517"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320217351"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320213272"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
            </div>


            {/* Start Cockpit */}

            <div className="col mb-5">
              <img
                loading="lazy"
                className="img-fluid iconEDV"
                src={iconCockpit}
                alt="S"
              />
            </div>

            <div className="col mb-4">
              <p className="laFormation ">
                <strong>LE COCKPIT DU CERCLE DES VISIONNAIRES :</strong>
              </p>
            </div>

            <div className="col  mb-4">
              <p className="laFormation_text ">
                Le ‘cockpit’ est la cabine de pilotage du Cercle des
                Visionnaires, d’où son nom ! Il contient toutes les commandes et
                les instruments nécessaires au pilotage de votre Liberté
                Financière et votre Éveil Spirituel.
              </p>
              <p className="laFormation_text mt-3">
                Vous y retrouverez plusieurs outils puissants, ludiques et
                faciles à prendre en main:
              </p>
            </div>

            {/* video trombinoscope */}
            {/* <div className="row justify-content-center  pt-xl-n5">
              <div className="col d-none d-xl-block"></div>
              <div className="col-xl-6  col-xs-12 bg-white text-center">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/8ogeq4avl7"
                    playing={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col d-none d-xl-block"></div>
            </div> */}


            <div className="row ">

              
            <div className="col-xs-12 col-sm-3  p-xl-4 ">
                Carnet de Santé
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/087ed5mari"
                    controls={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 p-xl-4 malalaka ">
                Easy Nego
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/xdksao3wij"
                    controls={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 p-xl-4 malalaka ">
                Gestion Locative
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/33sc07d6w4"
                    controls={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 p-xl-4 malalaka ">
                La Communauté
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://flow-corporation.wistia.com/medias/8ogeq4avl7"
                    controls={false}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>


              {/* <div className="col-xs-12 col-sm-3  p-xl-4 malalaka ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320209575"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div> */}
            </div>

            <div className="col">
              <Row7 />
            </div>
            <div className="row ">
              <div className="col">
                <img className="img-fluid" src={pic3} alt="S" />
              </div>
            </div>

            <div className="title_netflix mt-4">
              <strong>
                Témoignages des Membres du Cercles des Visionnaires !{" "}
              </strong>
            </div>



            <div className="row ">
              <div className="col-xs-12 col-sm-3 p-xl-4 ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320214932"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320214067"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320219081"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320214622"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
            </div>

            <a
              target="_blank"
              href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
            >
              <button className="btn btn-info reserver text-white shadow  mb-5 ">
                {" "}
                REVENIR SUR LA PAGE DU COLOCTIONNEUR
              </button>
            </a>

            <div className="row">
              <div className="col">
                <img className="img-fluid wc wbl" src={wbl} alt="S" />
                <img className="img-fluid wc pic4 mb-4 " src={pic4} alt="S" />
                <img className="img-fluid wc wbr" src={wbr} alt="S" />
              </div>
            </div>

            <a
              target="_blank"
              href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
            >
              <button className="btn btn-info reserver text-white shadow  mb-5 ">
                {" "}
                REVENIR SUR LA PAGE DU COLOCTIONNEUR
              </button>
            </a>

            <div className="title_netflix mt-4">
              <strong>
                Témoignages des Membres du Cercles des Visionnaires !{" "}
              </strong>
            </div>


            <div className="row ">
              <div className="col-xs-12 col-sm-3 p-xl-4">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320220485"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320218680"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320217875"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url="https://player.vimeo.com/video/320220410"
                    controls={true}
                    width="100%"
                    height="100%"
                    background-color="#fff"
                  />
                </div>
              </div>
            </div>
            <a
              target="_blank"
              href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
            >
              <button className="btn btn-info reserver text-white shadow  mb-5 ">
                {" "}
                REVENIR SUR LA PAGE DU COLOCTIONNEUR
              </button>
            </a>
          </div>
          <img
            loading="lazy"
            className="img-fluid lastImage"
            src={rambony}
            alt="S"
          />
          <img loading="lazy" className="c5 img-fluid" src={c5} alt="SEF" />
          <img loading="lazy" className="c4 img-fluid" src={c4} alt="SEF" />

          <a
            target="_blank"
            href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
          >
            <button className="btn btn-info lastButton text-white shadow  mb-5 ">
              REVENIR SUR LA PAGE DU COLOCTIONNEUR
            </button>
          </a>
        </div>
      </div>
      <div className={`${daty <= dateLimit ? "time" : " d-none"} `}>
        <p className="time__first">
          Fin de l'offre dans
          <CountDownDaily
            className="count"
            endDate={"2021-05-11T23:59:59+01:00"}
            dayAffix="j"
            hourAffix="h"
            minutesAffix="min"
            secondAffix="sec"
          />
        </p>
        <a
          className="bandeau"
          target="_blank"
          href="https://www.le-coloctionneur.fr/colocv7-pdv-inscription/"
        >
          <p className="time__second ">
            {" "}
            REVENIR SUR LA PAGE DU COLOCTIONNEUR
          </p>
        </a>
      </div>

      <div className={`${daty >= dateLimit ? "d-none" : " d-none"}`}>
        <h1 className="finished__text">
          L'offre d'essai du Cercle des Visionnaires de est terminée... !
        </h1>
      </div>
    </div>
  );
}

export default App;
